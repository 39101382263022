
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























































































































































































































































































































































































































































































































































































































































































































































































































































































$bp: l;

.funnel {
  @include get-all-space;

  position: fixed;
  z-index: 900;
  overflow: auto;
  background: $white;

  &.sidebar-visible {
    overflow: hidden;

    &::before {
      @include get-all-space;

      content: '';
      position: fixed;
      z-index: 5;
      background-color: rgba($c-blue-dark, 0.8);
    }

    @include mq($bp) {
      display: none;
    }
  }
}

.funnel-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;

  ::v-deep {
    .assistant-sidebar__print,
    .assistant-sidebar__selection__ctas {
      display: none;
    }
  }

  @include mq($bp) {
    display: block;
    padding-bottom: 10rem;
  }
}

.funnel-step__title-outer {
  margin-bottom: 3rem;

  p {
    margin: 0;
  }
}

.funnel-step__title {
  @extend %fw-bold;

  display: block;
  margin-top: 0;
  margin-bottom: 3rem !important;
  font-family: $ff-alt;
  font-size: 2.6rem;
  line-height: 1.2;

  i {
    display: block;
    color: $c-pink-medium;
    font-style: normal;
  }

  ::v-deep img {
    display: block;
    width: 30rem;
    margin-top: 2rem;
  }

  ::v-deep a {
    color: $c-pink-medium;
  }

  @include mq($bp) {
    font-size: 3.2rem;
  }
}

.funnel-step__subtitle {
  margin: 0 0 4rem;

  @include mq(m) {
    margin: 0 0 6rem;
  }
}

.funnel-header {
  background: $c-white-dark;

  @include mq($bp) {
    border-top: 5rem solid $c-blue-dark;
  }
}

.funnel-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include mq($bp) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}

.funnel-header__content__logo {
  line-height: 1;
}

.funnel-header__content__btn {
  margin-left: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.funnel-back {
  margin-bottom: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $gray-lightest;

  @include mq($bp) {
    margin-bottom: 5rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.funnel-back__link {
  @extend %fw-bold;

  display: flex;
  align-items: center;
  font-family: $ff-alt;
  line-height: 1;
  background: transparent;
  border: 0;
  transition: color 0.15s;

  .icon {
    width: 0.8rem;
    height: 1rem;
    margin-right: 1.8rem;
    transform: rotate(180deg);
    transition: transform 0.15s, fill 0.15s;
  }

  &:hover {
    color: $c-pink-medium;

    .icon {
      fill: $c-pink-medium;
      transform: rotate(180deg) translateX(0.5rem);
    }
  }
}

.funnel-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  @include mq($bp) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.funnel-body-btn {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  background: $white;
  border: 1px solid $c-green-light;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba($black, 0.1);
  cursor: pointer;
  transition: box-shadow 0.15s;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba($black, 0.2);
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ::v-deep strong {
    color: $c-green-light;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + div {
    background-color: $c-green-light;
  }
}

.funnel-body-btn__check {
  position: relative;
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  background-color: $c-gray-light;
  border-radius: 50%;

  &::before {
    @include center-xy;

    content: '';
    width: 50%;
    height: 50%;
    background-color: $white;
    border-radius: 50%;
  }
}

.funnel-body__content {
  @include mq($bp) {
    flex: 1;
    flex-shrink: 0;
    max-width: 53rem;
    margin-right: 3rem;
  }
}

.funnel-body__update-address {
  margin-bottom: 7rem;
}

.funnel-body-btn-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;

  @include mq(m) {
    margin-top: 5rem;
  }
}

.funnel-body__headline {
  @extend %fw-bold;

  display: block;
  margin-bottom: 2.5rem;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.funnel-body__intro {
  margin-bottom: $spacing * 2;
}

.funnel-body__success-title {
  margin-bottom: 1rem;
}

.funnel-body__success-text {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.5rem;

  @include mq($bp) {
    font-size: 1.8;
  }
}

.funnel-body__success-tip {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3rem;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  line-height: 2.2rem;
  background-color: $c-light-gray;
  border-radius: 1rem;

  ::v-deep a {
    color: $c-pink-medium;
  }

  svg {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-anthracite-light;
  }
}

.funnel-body__form {
  > * {
    margin-bottom: 2rem;
  }

  @include mq($bp) {
    > * {
      margin-bottom: 2rem;
    }
  }
}

.funnel-body__choice {
  margin-bottom: 5rem;

  .funnel-body-btn-list {
    margin-top: 0;
  }
}

.funnel-body__form__hours-outer {
  overflow: hidden;
  margin: 3rem 0;
}

.funnel-body__form__hours {
  margin-left: 3rem;
}

.funnel-body__form__more {
  margin-bottom: 4rem;
  text-align: center;
}

.funnel-body__form__more__link {
  padding: 0;
  color: $c-pink-medium;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  background-color: transparent;
  border: 0;

  &:hover {
    text-decoration: none;
  }
}

.funnel-body__form__row {
  display: flex;
  justify-content: space-between;

  > * {
    flex-basis: 48%;
  }
}

.funnel-body__form__error {
  @extend %fw-bold;

  color: $c-pink-medium;
}

.funnel-body__form__submit {
  display: flex;
  margin: 4rem 0 5rem auto;

  @include mq($bp) {
    margin: 5rem 0 0 auto;
  }
}

.funnel__footer {
  display: flex;
  justify-content: space-between;
  background: $c-blue-dark;

  @include mq($bp) {
    display: none;
  }
}

.funnel__footer__data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;
  color: $white;
  font-family: $ff-alt;
  line-height: 1;

  @include mq(s) {
    padding: 0 3rem;
  }

  ::v-deep .product-card__main {
    display: none;
  }

  ::v-deep .product-card__details {
    z-index: 5;
    position: relative;
    background: $c-blue-dark;
  }
}

// .funnel__footer__data__duration {
//   margin-right: 2rem;

//   strong {
//     display: block;
//     margin-bottom: 0.5rem;
//     font-size: 1.6rem;
//   }

//   small {
//     color: rgba($white, 0.6);
//     font-size: 1.4rem;
//   }
// }

// .funnel__footer__data__price {
//   font-size: 3.2rem;
// }

.funnel__footer__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 9rem;
  line-height: 1;
  background: $c-anthracite-lighter;
  cursor: pointer;

  svg {
    width: 2.5rem;
    height: 2rem;
    fill: $white;
    transform: rotate(-180deg);
  }
}

.funnel-sidebar {
  width: 100%;
  min-width: 30rem;
  max-width: 38rem;

  @include mq($until: $bp) {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    min-width: auto;
    max-width: none;
    height: 100vh;
    padding: 3rem 3rem 0;
    background: $white;
    transform: translateY(100%);
    transition: transform 0.4s $ease-out-quad;
    pointer-events: none;

    .sidebar-visible & {
      pointer-events: auto;
      transform: translateY(0);
    }
  }
}

.funnel-sidebar__close {
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background: transparent;
  border: 0;

  @include mq($bp) {
    display: none;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .icon {
    width: 100%;
    height: 100%;
    fill: $gray-lighter;
  }
}

.funnel-sidebar__back {
  margin-top: 4rem;

  ::v-deep .btn__link {
    width: 100%;
  }
}

.funnel-sidebar__offer {
  ::v-deep .offer__footer__btn {
    display: none;
  }
}

.voo-logo {
  display: inline-block;
  will-change: transform;
  transition: fill 0.2s ease-out;

  ::v-deep .icon {
    fill: $c-pink-dark;
  }
}
