
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































.voo-advantages,
[class*='voo-advantages--'] {
  position: relative;
}

.voo-advantages__title {
  margin-top: 0;
  margin-bottom: $spacing * 2.5;
}

.voo-advantages__list {
  @extend %list-nostyle;

  margin-bottom: $spacing * 1.5;

  @include mq('l') {
    margin-bottom: $spacing * 3;
  }
}

.voo-advantages__list__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: $spacing * 0.75;

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    width: 3rem;
    min-width: 3rem;
    height: auto;
    margin-right: $spacing * 0.5;

    @include mq('l') {
      width: 3.6rem;
      min-width: 3.6rem;
      height: auto;
      margin-right: $spacing;
    }
  }

  @include mq('l') {
    align-items: center;
  }
}

.voo-advantages__list__item__text {
  font-size: 2rem;
}

.configurator__button-wrapper {
  margin-top: $spacing * 0.75;

  .link {
    font-size: 1.6rem;
  }
}

.configurator__repricing {
  margin-top: $spacing;
  font-size: 1.6rem;
  opacity: 0.7;

  ::v-deep span {
    font-weight: 400;
    line-height: 2.2rem;
  }

  ::v-deep a {
    @extend %link--pink;

    font-family: $ff-default;
    font-size: 1.6rem;
    font-weight: 400;
    background-position: 100% 100%, 0 100%;
  }
}
