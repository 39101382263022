
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        














































$bp: m;

.toggle {
  display: flex;
  align-items: center;
}

.toggle__label {
  margin-right: 0.8rem;
  color: $c-anthracite-light;
  font-family: $ff-default;
  font-size: 1.3rem;

  @include mq($until: m) {
    display: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;

  &[disabled] {
    opacity: 0.35;
    pointer-events: none;
  }

  .input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  @include mq($bp) {
    width: 44px;
    height: 24px;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $c-gray-light;
  border-radius: 50px;
  transition: 0.4s;

  @include mq($bp) {
    border-radius: 36px;
  }
}

.slider__circle {
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba($black, 0.1), 0 1px 1px rgba($black, 0.16),
    0 3px 1px rgba($black, 0.1);
  transition: 0.4s;

  @include mq($bp) {
    width: 20px;
    height: 20px;
  }
}

.input:checked + .slider {
  background-color: $c-green-light;
}

.input:focus + .slider {
  box-shadow: 0 0 1px $c-gray-light;
}

.input:checked + .slider .slider__circle {
  transform: translateX(16px);

  @include mq($bp) {
    transform: translateX(20px);
  }
}
