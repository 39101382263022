
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        








































































































































































































































































$bp: m;

.configurator__section__header__icon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 3rem;
}

.advantages__list {
  margin-bottom: 5rem;

  &.has-counter {
    margin-top: $spacing * 1.75;
  }

  @include mq($until: $bp) {
    @include scrollbars(2px, $c-gray-lightest);

    display: flex;
    overflow: auto;
    margin: 0 $wrapper-spacing-s * -1 2.5rem;
    padding: $spacing $wrapper-spacing-s 1rem;
  }
}

.advantages__list__item {
  position: relative;
  z-index: 1;
  container-type: inline-size;

  &.unavailable {
    pointer-events: none;

    &::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      background: rgba($white, 0.7);
      border-radius: 1rem;
    }
  }

  .selectable-block {
    flex: 1;
    width: 100%;
    margin-bottom: 3rem;
  }

  @include mq($until: $bp) {
    flex-shrink: 0;
    /* stylelint-disable-next-line declaration-no-important */
    width: 16.5rem !important;
  }
}

.advantages__list__item__picture {
  position: relative;
  width: 100%;
  max-width: 15.5rem;
  height: 12.5rem;
  margin: 0 auto 1rem;

  img {
    @include image-fit(contain);
  }
}

.advantages__list__item__label {
  margin-bottom: 1rem;
}

.advantages__list__item__title {
  line-height: 2rem;

  @include mq($bp) {
    line-height: 2.4rem;
  }
}

.advantages__list__item__small {
  position: absolute;
  bottom: 0;
}

.advantage__customer {
  @extend %fw-bold;

  font-family: $ff-alt;
}

.advantages__list__item__unavailable {
  @extend %text-center;
  @include center-xy;

  z-index: 9;
  display: none;
  width: 15rem;
  padding: 2rem;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1.2;
  text-align: center;
  background-color: $c-blue-dark;
  border-radius: 8px;

  .unavailable & {
    display: block;
  }

  strong {
    @extend %fw-black;

    display: block;
    white-space: nowrap;
  }

  .blue & {
    background: $c-light-blue;
  }

  .cyan & {
    background: $c-cyan-contrast;
  }

  .orange & {
    background: $c-orange;
  }

  .burgundy & {
    background: $c-burgundy;
  }

  .midnightblue & {
    background: $c-blue-dark;
  }

  .pink & {
    background: $c-pink-light;
  }
}

.configurator__small {
  position: absolute;
  bottom: 0;
}

.advantages__list__item__stock {
  position: absolute;
  top: 0;
  left: -10px;
  display: inline-flex;
  padding: 0.4rem 1.5rem;
  background-color: $c-pink-medium;
  border-radius: 5px;
  transform: translateY(-50%);

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 4px;
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #68023f;
    transform: rotate(45deg);
  }

  span {
    color: white;
    font-family: $ff-alt;
    font-style: normal;
    font-weight: 700;
    font-size: 5.7cqw;
    line-height: 2rem;
    text-transform: uppercase;
  }
}

.configurator__info {
  margin-bottom: $spacing;

  ::v-deep p {
    margin: 0;
  }
}

.configurator__countdown {
  .icon {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: $spacing * 0.5;
  }
}
