
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





























































































.selectable-block,
[class*='selectable-block--'] {
  padding: 1.6rem 1.2rem;
  background-color: $white;
  border: 1px solid $c-blue-light-pale;
  border-radius: 10px;
  transition: border 0.15s, opacity 0.15s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    border-color: $c-anthracite-light;
  }
  &.is-selected {
    // border-width: 2px;
    // border-color: $c-anthracite-light;
    // outline: 2px $c-anthracite-light solid;
    // box-shadow: inset $c-anthracite-light
    box-shadow: inset 0px 0px 0px 2px $c-anthracite-light;
  }

  &.checkbox {
    padding: 1.6rem 3rem;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.selectable-block__check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 auto;
  background-color: $c-blue-light-pale;
  border-radius: 50%;
  transition: background-color 0.15s;

  [class*='selectable-block'].is-selected & {
    background-color: $c-anthracite-light;
  }

  .radio.is-selected & {
    &::after {
      content: '';
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      background-color: $white;
      border-radius: 50%;
    }
  }

  .checkbox & {
    order: -1;
    margin: 0 3rem 0 0;
    border-radius: 0;
  }
}

.selectable-block__check__icon {
  display: none;
  width: 1.7rem;
  fill: $white;

  [class*='selectable-block']:not(.radio).is-selected & {
    display: block;
  }
}
