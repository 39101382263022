
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        









































































































































































































































































































































































































































$bp: m;

.tv__rgus {
  // margin-bottom: 4rem;
}

.tv__rgu {
  display: flex;
  flex-direction: column;
  align-items: center;

  .selectable-block {
    width: 100%;
  }
}

.tv__rgu__spec {
  flex: 1;
  margin-bottom: 1rem;
}

.net__rgu__spec__price {
  margin-bottom: 1.5rem;
}

.tv__extras {
  margin-top: $spacing * 1.5;
}

.tv__extras__intro {
  @extend %fw-light;

  margin-bottom: 3rem;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

.tv__extras__list {
  margin-bottom: 2rem;

  @include mq($until: $bp) {
    @include scrollbars(2px, $c-gray-lightest);

    display: flex;
    overflow: auto;
    margin: 0 $wrapper-spacing-s * -1 2.5rem;
    padding: 0 $wrapper-spacing-s 1rem;
  }
}

.tv__extras__item-outer {
  position: relative;
  padding-bottom: 2.5rem;

  .configurator__small {
    position: absolute;
    bottom: 0;
  }

  @include mq($bp) {
    padding-bottom: 3rem;
  }
}

.tv__extras__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

  &.center {
    position: relative;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    p {
      @include center-x;

      top: 2rem;
      margin: 0;
    }
  }

  @include mq($until: $bp) {
    flex-shrink: 0;
    /* stylelint-disable-next-line declaration-no-important */
    width: 16.5rem !important;
  }

  @include mq($bp) {
    &.center {
      min-height: 14rem;

      p {
        top: 4rem;
      }
    }
  }
}

.tv__extras__item__picture {
  max-height: 3rem;
  margin-bottom: 2rem;
}

.tv__extras__item__label {
  margin-bottom: 2rem;
}

.configurator__section__header__icon {
  width: 3rem;
  height: 2rem;
  margin-right: 1.7rem;

  @include mq(m) {
    width: 4rem;
    height: 2.6rem;
  }
}

.configurator__info {
  margin-top: $spacing;
}

.configurator__oneshot {
  .configurator__text {
    text-align: left;
  }
}

.configurator__oneshot__section {
  width: 100%;
}

.selectable-block__picture {
  height: 3.2rem;
  margin-right: $spacing * 0.5;
}

.price {
  margin-left: auto;
}

.price__final {
  @extend %fw-bold;

  margin-left: 1rem;
  color: $c-mandarine;
  font-size: 1.8rem;
}

.price__original {
  @extend %fw-medium;

  position: relative;
  margin-top: 2px;
  font-size: 1.8rem;

  &.is-crossed {
    &::before {
      @include center-y;

      content: '';
      left: -10%;
      width: 120%;
      height: 3px;
      background-color: rgba($c-mandarine, 0.7);
      border-radius: 3px;
    }
  }
}
