
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        












































































































































































































































































.configurator-landing {
  position: relative;

  &.is-fullpage {
    position: revert;

    .rgu-selection {
      margin-top: 3rem;

      @include mq(l) {
        margin-top: 7rem;
      }
    }

    .rgu-selection__loader {
      position: fixed;
    }

    .rgu-selection__subtitle {
      @include mq(l) {
        margin: 0 0 6rem;
      }
    }

    .rgu-selection__rgus {
      width: 100%;
      margin: 0 0 3rem;
    }

    .rgu-selection__rgus__item {
      @include mq(m) {
        padding: 4.5rem 4rem;
      }
    }
  }

  &.is-fullpage:not(&.is-home-configurator) {
    .rgu-selection__title {
      margin-bottom: 1rem;
      font-size: 3.2rem;

      @include mq(l) {
        margin: 0 0 4rem;
        font-size: 7.2rem;
        line-height: 7.6rem;
      }
    }

    @include mq(l) {
      height: 100%;
      min-height: 100vh;
    }
  }

  @include mq(l) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.rgu-selection {
  width: 100%;
}

.rgu-selection__loader {
  @include get-all-space;

  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;

  img {
    width: 8rem;
  }
}

.rgu-selection__title {
  margin: 0 0 1rem;

  @include mq(l) {
    margin: 0 0 2rem;
  }
}

.rgu-selection__subtitle {
  @extend %fw-light;

  margin: 0 0 2rem;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @include mq(l) {
    margin: 0 0 4.5rem;
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
}

.rgu-selection__title,
.rgu-selection__subtitle {
  @include mq(m) {
    text-align: center;
  }
}

.rgu-selection__rgus {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 0 0 3rem;
  background-color: $white;
  border-radius: 10px;

  @include mq(xl) {
    gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    width: max(970px, 85%);
    margin: 0 auto 4.5rem;
  }
}

.rgu-selection__rgus__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(m) {
    flex-direction: row;
    padding: 3rem;

    .homepage & {
      padding: 4.5rem 4rem;
    }

    ::v-deep .selectable-block__check {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.rgu-selection__rgus__item__icon {
  width: auto;
  height: 100%;
  max-height: 2.5rem;
  margin: 0 0 1rem;

  svg {
    width: 100%;
    height: 100%;
  }

  @include mq(m) {
    max-height: 4rem;
    margin: 0 2rem 0 0;
  }
}

.rgu-selection__rgus__item__title {
  @extend %fw-bold;

  margin: 0 0 1rem;
  font-size: 2rem;
  line-height: 2.4rem;
  text-transform: uppercase;

  @include mq(m) {
    margin: 0;
    text-transform: none;
  }
}

.rgu-selection__rgus__price {
  margin-bottom: $spacing;

  ::v-deep {
    .price-htva {
      text-align: center;
    }
  }
}

.rgu-selection__error-outer {
  text-align: center;
}

.rgu-selection__error {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 4rem;
  padding: 1.5rem;
  color: $c-pink-medium;
  background-color: #fdf3f8;
  border-radius: 10px;

  .icon {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.5rem;
    fill: $c-pink-medium;
  }
}

.rgu-selection__submit {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
}

.rgu-selection__link {
  display: flex;
  justify-content: center;
  margin-top: $spacing;
}

.rgu-selection__speedlines-1,
.rgu-selection__speedlines-2 {
  position: absolute;
  z-index: -1;
}

.rgu-selection__speedlines-container {
  @include get-all-space;

  overflow: hidden;
  pointer-events: none;
}

.rgu-selection__speedlines-1 {
  bottom: 0;
  left: 0;
  width: 38rem;
  transform: translate(0, 50%);
}

.rgu-selection__speedlines-2 {
  @include center-y;

  top: 30%;
  right: 0;
  width: 20rem;
  transform: translate(0, -50%);

  @include mq($until: l) {
    display: none;
  }
}
