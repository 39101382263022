
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































































































































































































.tel__rgus {
  margin-bottom: 4rem;
}

.tel__rgus__item {
  width: 100%;
  max-width: 34rem;
  text-align: center;

  .selectable-block {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.configurator__section__header__icon {
  width: 2rem;
  height: 2.2rem;
  margin-right: 2.8rem;

  @include mq(m) {
    width: 2.4rem;
    height: 2.6rem;
    margin-right: 3.5rem;
  }
}

.tel__compare {
  margin-bottom: $spacing;
}

.configurator__button.configurator__info {
  margin-top: 4rem;
}
